@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  @apply h-full;
}

body {
  @apply h-full;
}

.border-background {
  background: linear-gradient(
    180deg,
    rgba(230, 255, 252, 0.2) -2.14%,
    rgba(214, 218, 255, 0) -2.14%,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(239, 255, 253, 0.2) -2.12%,
    rgba(243, 255, 254, 0.2) -2.11%,
    rgba(154, 164, 178, 0.2) 46.14%,
    rgba(249, 255, 254, 0.2) 104.29%
  );
}

.border-background-bottom {
  background: linear-gradient(
    90deg,
    rgba(230, 255, 252, 0.2) -2.14%,
    rgba(214, 218, 255, 0) -2.14%,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(239, 255, 253, 0.2) -2.12%,
    rgba(243, 255, 254, 0.2) -2.11%,
    rgba(154, 164, 178, 0.2) 46.14%,
    rgba(249, 255, 254, 0.2) 104.29%
  );
}

.dottedLine {
  background: linear-gradient(#0e776d 50%, rgba(255, 255, 255, 0) 0%);
  background-size: 1px 10px;
  background-repeat: repeat-y;
}

.dottedLine2 {
  background: linear-gradient(#cdd5df 50%, rgba(255, 255, 255, 0) 0%);
  background-size: 1px 8px;
  background-repeat: repeat-y;
}

/* Loader styles */
.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #e5e7eb;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #001d7e;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.course_requirement_content > ol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.course_requirement_content > ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.course_requirement_content > ol > li {
  padding-left: 36px;
  background-image: url('/Images/li.svg');
  background-repeat: no-repeat;
  background-position: left center;
  font-size: 16px;
  line-height: 24px;
}

.course_requirement_content > ul > li {
  padding-left: 36px;
  background-image: url('/Images/li.svg');
  background-repeat: no-repeat;
  background-position: left center;
  font-size: 16px;
  line-height: 24px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .course_requirement_content > ul > li {
    background-image: url('/Images/li.svg');
  }

  .course_requirement_content > ol > li {
    background-image: url('/Images/li.svg');
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #e3e8ef;
  border-radius: 8px;
}

.swiper-wrapper {
  margin-bottom: 0px;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
